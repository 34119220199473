import React, {useEffect, useLayoutEffect, useState} from "react";
import axios from '../Services/AxiosService';
import {useNavigate} from "react-router-dom";
import './Statistic.css'
import {variables} from "../../Variables";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar,} from 'recharts';
import StatisticSwitcher from "./StatisticSwitcher";
import {StatisticAddHourModal} from "./StatisticAddHourModal";
import {colors} from "../Colors"
import {forEach} from "react-bootstrap/ElementChildren";
import {Button} from "react-bootstrap";
import { ReactComponent as CopyIcon } from "../../static/copy.svg";

const ENTER_KEY = 13

export function Statistic(props) {
    const navigate = useNavigate();

    const [totalCount, setTotalCount] = useState('')
    const [activeMenuItem, setActiveMenuItem] = useState({label:'Затраченное время', value: 'time'})
    const [statistic, setStatistic] = useState([]);
    const [statisticSum, setStatisticSum] = useState([]);
    const [users, setUsers] = useState([]);
    const [barChartStatistic, setBarChartStatistic] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isSumStatistic, setIsSumStatistic] = useState(true);
    const [duration, setDuration] = useState('week');
    const [candidateFields, setCandidateFields] = useState([])
    var { 0: vacancies, 1: setVacancies } = useState([]);
    var { 0: recrutiers, 1: setRecrutiers } = useState([]);
    var { 0: currentVacancy, 1: setCurrentVacancy } = useState('');
    var { 0: currentRecrutiers, 1: setCurrentRecrutiers } = useState('');
    var { 0: currentRecrutierName, 1: setCurrentRecrutierName } = useState('Все рекрутеры');

    useLayoutEffect(() => {
      get_vacancies();
      get_recrutiers();
    }, []);
  
    var get_vacancies = () => {
      fetch(variables.API_URL + "/api/vacancy_id_and_name/", {
        headers: {
          Accept: " application/json",
          "Content-Type": " application/json",
          Authorization: `Bearer ${props.token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setVacancies([{id: '', name: "Все вакансии"},...data]);
        });
    };

    var get_recrutiers = () => {
        fetch(variables.API_URL + "/api/get_recruter_options/", {
            headers: {
                Accept: " application/json",
                "Content-Type": " application/json",
                Authorization: `Bearer ${props.token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                let new_data = []
                data.forEach(item => {
                    new_data.push({id: String(item.value), name: item.label});
                })
                setRecrutiers([{id: '', name: "Все рекрутеры"},...new_data]);
            });
    }


    useLayoutEffect(() => {
        getStatistic()
    }, [selectedDate, activeMenuItem.value, duration, currentVacancy, currentRecrutiers]);

    const getStatistic= () =>{
        axios.get(variables.API_URL + '/api/statistic/', {
            headers: {
                "Accept": " application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${props.token}`
            },
            params: {date: selectedDate, type: activeMenuItem.value, duration: duration, vacancy_id: currentVacancy, recruter_id: currentRecrutiers}
        })
            .then(response => {
                let data = response.data;
                setUsers(data.users);
                setTotalCount(data.total)
                if (activeMenuItem.value !== "candidate") {
                    sumStatistic(data.statistic)
                    setStatistic(data.statistic);
                } else {
                    setBarChartStatistic(data.statistic_bar)
                    setStatistic(data.statistic)
                    sumStatistic(data.statistic, true, false)
                    setCandidateFields(data.fields)
                }
            })
    }


    // const totalStatistic = (list_statistic) => {
    //     let totalStat = []
    //     let totalItem = {}
    //     for (let item of list_statistic) {
    //         totalItem = {"name": item["name"], "Всего": 0}
    //         for (let key of Object.keys(item)) {
    //             if (key !== "name") {
    //                 totalItem["Всего"] += item[key]
    //             }
    //         }
    //         totalStat.push(totalItem)
    //     }
    //     setStatistic(totalStat)
    //     sumStatistic(totalStat, true, false)
    // }

    const sumStatistic = (list_statistic, countSum=true, countBar=true) => {
        let calculated_statistic = []
        let previous_user_count = {}
        for (let item of list_statistic) {
            let users_list = Object.keys(item)
            let user_count = {}
            for (let user of users_list) {
                if (user === 'name') user_count['name'] = item['name']
                else {
                    user_count[user] = previous_user_count[user] ? previous_user_count[user] + item[user]: item[user]
                    previous_user_count[user] = user_count[user]
                }
            }
            calculated_statistic.push(user_count)
        }
        // console.log(previous_user_count)
        let newBarChartStatistic = []
        for (let user_sum of Object.keys(previous_user_count)){
            if (previous_user_count[user_sum] !== 0)
                newBarChartStatistic.push({'name': user_sum, 'count': previous_user_count[user_sum]})
        }
        newBarChartStatistic.sort((a, b) => (a.count > b.count) ? -1 : 1)
        if (countBar) setBarChartStatistic(newBarChartStatistic)
        if (countSum) setStatisticSum(calculated_statistic)
    }

    const increase_date = () => {
        let new_date
        if (duration==='year') new_date = new Date(selectedDate.setFullYear(selectedDate.getFullYear() + 1))
        if (duration==='halfmonth') {
            if (selectedDate.getDate() < 15) new_date = new Date(selectedDate.setDate(16))
            else new_date = new Date((new Date(selectedDate.setDate(1))).setMonth(selectedDate.getMonth() + 1))
        }
        if (duration==='month') new_date = new Date(selectedDate.setMonth(selectedDate.getMonth() + 1))
        if (duration==='week') new_date = new Date(selectedDate.setDate(selectedDate.getDate() + 7))
        setSelectedDate(new_date)
    }
    const decrease_date = () => {
        let new_date
        if (duration==='year') new_date = new Date(selectedDate.setFullYear(selectedDate.getFullYear() - 1))
        if (duration==='month') new_date = new Date(selectedDate.setMonth(selectedDate.getMonth() - 1))
        if (duration==='halfmonth') {
            if (selectedDate.getDate() < 15) new_date = new Date((new Date(selectedDate.setDate(16))).setMonth(selectedDate.getMonth() - 1))
            else new_date = new Date(selectedDate.setDate(1))
        }
        if (duration==='week') new_date = new Date(selectedDate.setDate(selectedDate.getDate() - 7))
        setSelectedDate(new_date)
        setSelectedDate(new_date)
    }
    const toTimeFormat = (decimal, fixed = 0) => parseInt(Math.floor(decimal/60) + decimal%60/60);


    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
          return (
            <div className="custom-tooltip">
              <p className="label">{label}</p>
              {payload.map(item => {
                  if (item.value) {
                      return (
                          <p style={{color: item.color}}
                             key={item.name}>{item.name}: {Math.floor(item.value / 60)} ч {item.value % 60} м</p>
                      )
                  }
              })}
            </div>
          );
        }

        return null;
      };

    const CustomTooltipNotZero = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                  <p className="label">{label}</p>
                  {payload.map(item => {
                      if (item.value) {
                          return (
                              <p style={{color: item.color}} key={item.name}>{item.name}: {item.value}</p>
                          )
                      }
                  })}
                </div>
            )
        }
    }

    const copyRecruterStatisticToClipboard = () => {
        let statistic = ""
        const timestatistic = isSumStatistic?statisticSum:statistic
        const start = `${timestatistic[0].name}`
        const end = `${timestatistic[timestatistic.length-1].name}`
        barChartStatistic.forEach(item => {
            statistic += `${currentRecrutierName.split(" ").slice(1, currentRecrutierName.split(" ").length).join(" ")}\t${start.split(" ")[start.split(" ").length-1]}-${end.split(" ")[end.split(" ").length-1]}\t${item.name.split(" - ")[0]}\t${item.name.split(" - ")[1]}\t${Math.floor(item.count/60)} ч ${item.count%60} м\n`
        })
        navigator.clipboard.writeText(statistic);
    }

    return (
        <div className='statistic_container'>
            <StatisticSwitcher setActiveMenuItem={setActiveMenuItem} activeMenuItem={activeMenuItem}
            setIsYearItem={setDuration} isYearItem={duration}/>
            <div className='statistic_container_header'>
                {duration!=='day_number' && <button className='statistic_container_header_button' onClick={decrease_date}>←</button>}
                <div className='statistic_container_header-title-container'>
                    <div className='statistic_container_header-title'>{activeMenuItem.label}</div>
                    <div className='switcher'>
                        <input
                            id={'isSumStatistic'}
                            className="switcher__input"
                            type="checkbox"
                            checked={isSumStatistic}
                            onChange={()=>setIsSumStatistic(!isSumStatistic)}
                        />
                        <label htmlFor={'isSumStatistic'} className="switcher__switcher"></label>
                        <div>Накопом</div>
                    </div>
                    {activeMenuItem.value === 'recruiters' &&
                        <select
                            className="form-select statistic_select_vacancy"
                            onChange={(e) => {
                                setCurrentRecrutiers(e.target.value)
                                setCurrentRecrutierName(e.target.options[e.target.selectedIndex].text);
                            }}
                            value={currentRecrutiers}
                        >
                            {recrutiers?.map((recrutier) => {
                                return (
                                    <option key={recrutier.id} value={recrutier.id}>
                                        {recrutier.id} {recrutier.name}
                                    </option>
                                );
                            })}
                        </select> ||
                        <select
                            className="form-select statistic_select_vacancy"
                            onChange={(e) => {
                                setCurrentVacancy(e.target.value)
                            }}
                            value={currentVacancy}
                        >
                            {vacancies?.map((vacancy) => {
                                return (
                                    <option key={vacancy.id} value={vacancy.id}>
                                        {vacancy.id} {vacancy.name}
                                    </option>
                                );
                            })}
                        </select>
                    }
                    {props.isSuperUser && activeMenuItem.value === 'time' &&
                        <button type="button" className="btn btn-primary m-2 float-end"
                                data-bs-toggle="modal"
                                data-bs-target="#statsistic_add_hour_modal">
                            Добавить время
                        </button>}
                </div>
                <div className='statistic_container_header_total_and_button'>
                    {activeMenuItem.value !== "candidate" && <div
                        className='statistic_container_header_total'>Всего: {activeMenuItem.value === 'time' || activeMenuItem.value === "recruiters" ? `${Math.floor(totalCount / 60)} ч ${totalCount % 60} м`: totalCount}</div>}
                    {duration!=='day_number' && <button className='statistic_container_header_button' onClick={increase_date}>→</button>}
                </div>
            </div>
            <div className='statistic_container_graphic'>
                <ResponsiveContainer width="70%" height="100%">
                    <LineChart
                      width={500}
                      height={300}
                      data={isSumStatistic?statisticSum:statistic}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="5 5" />
                      <XAxis dataKey="name"
                             padding={{ left: 10, right: 10, top: 20 }}/>
                      <YAxis tickFormatter={activeMenuItem.value === 'time' || activeMenuItem.value === "recruiters" ? toTimeFormat: null}/>
                      <Tooltip content={activeMenuItem.value === 'time' || activeMenuItem.value === "recruiters" ? <CustomTooltip />: <CustomTooltipNotZero />} />
                      <Legend/>
                        {
                        activeMenuItem.value === "recruiters" ? users.map((user, index)=>{
                                return(<Line connectNulls type="monotone" dataKey={user} stroke={colors.vacancy[index % colors.vacancy.length]} strokeWidth={2}/>)
                        })
                        :
                        activeMenuItem.value !== "candidate" ? users.map((user, index)=>{
                            return(<Line connectNulls type="monotone" dataKey={user} stroke={colors.recruter[user]} strokeWidth={2}/>)
                        })
                        :
                        candidateFields.map((field, index)=>{
                            return(<Line connectNulls type="monotone" dataKey={field} stroke={colors.candidateStatus[field]} strokeWidth={2}/>)
                        })
                        }
                    </LineChart>
                  </ResponsiveContainer>
                {activeMenuItem.value === "recruiters" &&
                    <button
                        className="theme-btn btn btn-light btn-outline-primary copy-button"
                        onClick={copyRecruterStatisticToClipboard}
                    >
                        <CopyIcon style={{width:"100%"}}/>
                    </button>
                }
                <ResponsiveContainer width="30%" height="100%">
                    <BarChart
                        width={500}
                      height={300}
                      layout="vertical"
                      data={barChartStatistic}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="10 10" />
                      <XAxis type="number" tickFormatter={activeMenuItem.value === 'time' || activeMenuItem.value === "recruiters" ? toTimeFormat: null}/>
                      <YAxis type="category"
                             dataKey="name" tick={null}/>
                      <Tooltip content={activeMenuItem.value === 'time' || activeMenuItem.value === "recruiters" ? <CustomTooltip />: null}/>
                      {activeMenuItem.value !== "candidate" ?
                            <Bar stackId="a" dataKey={'count'} fill="#8884d8" />
                        :
                        candidateFields.map((field)=>{
                            return(<Bar stackId="a" dataKey={field} fill={colors.candidateStatus[field]} />)
                        })
                      }
                    </BarChart>
                  </ResponsiveContainer>
            </div>
            {props.isSuperUser && activeMenuItem.value === 'time' && <StatisticAddHourModal token={props.token}/>}
        </div>
    )
}