import React, { useState } from "react";
import "./ModalSocialNetwork.css";
import Modal from "react-bootstrap/Modal";
import { variables } from "../Variables";
import { useEffect } from "react";

const ModalSocialNetwork = (props) => {
  const [newSocialNetwork, setNewSocialNetwork] = useState({
    id: "",
    name: "",
    link: "",
    person: "",
  });

  const setNewSocialNetworkName = (e) => {
    setNewSocialNetwork({ ...newSocialNetwork, name: e.target.value });
  };

  const fetchPatchSocialNetwork = async () => {
    const response = await fetch(variables.API_URL + "/api/socialNetworks/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      body: JSON.stringify({
        ...newSocialNetwork,
        person_id: props.apply.person_id,
        vacancy_id: props.apply.vacancy_id,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      props.addSocialNetwork({
        id: data.id,
        name: data.name,
        link: data.link,
        person: data.person,
      });
      props.onModalClose();
    }
    // if (response.ok) {
    //   const data = await response.json();
    //   props.setApply({ ...props.apply, percentage_compliance: data });
    // }
  };

  const addSocialNetwork = () => {
    if (newSocialNetwork.name && newSocialNetwork.link) {
      fetchPatchSocialNetwork();
    }
  };

  useEffect(() => {}, []);

  return (
    <div className={"container modal-content rounded-5 shadow mt-0"}>
      <Modal
        show={props.isOpened}
        onHide={props.onModalClose}
        className={"modal fade"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="fw-bold mb-0">Добавить соцсеть</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <input
            placeholder="Название"
            className="modal_input mb-3"
            type="text"
            value={newSocialNetwork.name}
            onChange={(event) => {
              setNewSocialNetwork({
                ...newSocialNetwork,
                name: event.target.value.trim(),
              });
            }}
          ></input> */}
          <select
            onChange={setNewSocialNetworkName}
            placeholder="Название"
            value={newSocialNetwork.name}
            className="selectNewSocialNetwork"
          >
            <option value="" disabled>
              Выберите социальную сеть
            </option>
            <option value="Вконтакте">Вконтакте</option>
            <option value="Instagram">Instagram</option>
            <option value="Telegram">Telegram</option>
            <option value="Steam">Steam</option>
            <option value="TikTok">TikTok</option>
            <option value="Facebook">Facebook</option>
            <option value="Linked In">Linked In</option>
            <option value="Twitter">Twitter</option>
            <option value="Reddit">Reddit</option>
            <option value="GitHub">GitHub</option>
            <option value="YouTube">YouTube</option>
            <option value="Одноклассники">Одноклассники</option>
            <option value="Behance">Behance</option>
          </select>
          <input
            placeholder="Ссылка"
            className="modal_input"
            type="text"
            value={newSocialNetwork.link}
            onChange={(event) => {
              setNewSocialNetwork({
                ...newSocialNetwork,
                link: event.target.value.trim(),
              });
            }}
          ></input>
        </Modal.Body>
        {/* <Modal.Footer> */}
        <button
          className="btn btn-primary float-start"
          onClick={addSocialNetwork}
        >
          Добавить
        </button>
        {/* </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default ModalSocialNetwork;
